@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply text-base font-mono font-medium tracking-wider;
  }

  h1,
  h2,
  p {
    @apply font-thin text-white tracking-widest leading-loose text-medium;
  }

  button {
    @apply font-medium;
  }

  button {
    @apply tracking-wider font-normal uppercase;
  }
  a,
  p,
  h1,
  h2 {
    @apply selection:text-white;
  }

  svg {
    @apply text-xl;
  }
}

@layer components {
  .modal__overlay {
    @apply xl:block fixed pointer-events-auto left-0 top-0 z-10 w-full min-h-screen bg-black/50 hidden;
  }

  /* mobile links and main nav links*/
  .nav__link-mobileContainer {
    @apply transition-all duration-500 z-30;
  }

  .nav__link-mobile,
  .nav__link-main {
    @apply duration-100 transition-all hover:text-white text-gray uppercase;
  }

  .nav__link-main.active,
  .nav__link-mobile.active {
    @apply text-white duration-100;
  }

  .nav__link-icon {
    @apply text-xl inline ml-1 duration-200;
  }

  .sublinks.active {
    @apply text-dark-brown bg-gray-light;
  }

  /* dropdown links and container */
  .dropdown {
    @apply absolute max-h-[400px] top-[77px] w-[320%] bg-dark-brown z-20 py-3 overflow-scroll shadow-md hidden;
  }

  .dropdown.industry {
    @apply min-w-[220px] w-full;
  }

  .dropdown__mobile {
    @apply absolute -translate-y-1/2 left-full max-h-[300px] sm:min-w-[300px] min-w-[250px] bg-dark-brown py-3 overflow-scroll shadow-md hidden;
  }

  /* .dropdown__mobile.active {
    @apply relative max-h-[400px] w-[100%];
  } */

  .info__links {
    @apply py-2 px-6 hover:text-white hover:bg-very-gray duration-100 cursor-pointer uppercase w-full inline-block;
  }
  .info__links.active {
    @apply text-white bg-very-gray;
  }

  .youtube__image {
    @apply xxl:w-[570px] xxl:h-[480px] xl:w-[450px] xl:h-[440px] lg:w-[370px] lg:h-[400px] sm:w-[440px] sm:h-[440px];
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dropdown::-webkit-scrollbar,
.dropdown__mobile::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropdown-links,
.dropdown__mobile {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hero__bg {
  background-image: url("./assets/heading.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: dense;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.info {
  @apply fixed right-0 bottom-0 top-0 w-1/5 bg-white min-h-screen z-20;
}

.mobileMenu {
  @apply fixed left-0 bottom-0 top-0 w-full min-h-screen bg-black flex flex-col z-20 xl:hidden;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > img {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #fff;
  border-right: 4px solid #fff;
  animation: spinner 1s linear infinite forwards;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
